import {create} from "zustand";
import {toast} from "react-toastify";

export const useAuthStore = create((set) => {
    return {
        icon: true,//zstore,extra=>true
        online: false,//smart=>true
        gradient:false,//extra=>false
        user: null,
        settings: null,
        setUser: (user) => {
            set({user})

        },
        logOut: () => {
            set({user: null})

        },
        setSettings: (settings) => {
            set({settings})

        },
        copyText: (text) => {

            // Copy the text inside the input
            navigator.clipboard.writeText(text).then(function () {
                toast.success('تم النسخ إلى الحافظة')
            }).catch(function (error) {
                toast.error('خطأ في النسخ: ' + error);
            });
        }
    }
})